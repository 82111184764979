import React from "react";
import infoBlack from "../../../../assets/images/info-black.svg";
import questionWhite from "../../../../assets/images/question_white.svg";
import addWhite from "../../../../assets/images/add_white.svg";
import checkWhite from "../../../../assets/images/add_white.svg";
import closeWhite from "../../../../assets/images/close_white.svg";
import minusWhite from "../../../../assets/images/add_white.svg";

function Index() {
    return (
        <div className="element_info">
	        <a href="#"> 
	          <img src={infoBlack} className="icon" alt="info" />
	          <span style={{marginLeft: "10px"}}>Information</span>
	        </a>
	        {/* <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p> */}
	        <ul style={{marginTop: "20px"}}>  
	           <li className="contention">
	            	<a href="#">
		              	<img src={questionWhite} className="icon" alt="info" />
		              	<strong>Contention</strong>
		              	{/* <span>Tartışma Konusu</span> */}
	            	</a>
	          	</li>
          	  	<li className="reasons"> 
		            <a href="#">
			            <img src={addWhite} className="icon" alt="info" />
			            <strong>Reasons</strong>
			            {/* <span>İddia</span> */}
		            </a>
	          	</li>
		        <li className="evidence">
		            <a href="#">
		               <img src={checkWhite} className="icon" alt="info" />
		               <strong>Evidence</strong>
		               {/* <span>delil</span> */}
		            </a>
		        </li>
		        <li className="objection">
		            <a href="#">
		               <img src={closeWhite} className="icon" alt="info" />
		               <strong>Objection</strong>
		               {/* <span>İtiraz</span> */}
		            </a>
		        </li>
		        <li className="counter_evidence">
		            <a href="#">
		              	<img src={minusWhite} className="icon" alt="info" />
		                <strong>Counter Evidence</strong>
		                {/* <span>Karşı iddia</span> */}
		            </a>
		        </li>
	        </ul>
	    </div>
    );
}

export default Index;
