import React, { useState, useEffect } from "react";
import close from "../../../../assets/images/close-black.svg";
//import star from "../../../../assets/images/star.png";
import filter from "../../../../assets/images/filter-black.svg";
import Multiselect from "multiselect-react-dropdown";


function Index(props) {
    const [optionsOrg, setOptionsOrg] = useState([{ name: "Davoci", id: " Davoci" }]);
    const [sidesOption, setSidesOption] = useState([{ name: "Davoci", id: " Davoci" }]);
    const [documentTypeOption, setDocumentTypeOption] = useState([]);//useState([{ name: "Belge Tipi", id: "Belge Tipi" }]);
    const [evidenceOption, setEvidenceOption] = useState([]);
    const [selectedOrg, setSelectedOrg] = useState([]);
    const [selectedEvidence, setSelectedEvidence] = useState([]);
    const [selectedSide, setSelectedSide] = useState([]);
    const [selectedDocumentType, setSelectedDocumentType] = useState([]);
    const [countAllFilter, setAllFilter] = useState(0);
    const [clearFilterFlag, setClearFilterFlag] = useState(0);

    const multiselectOrgRef = React.createRef();
    const multiselectSideRef = React.createRef();
    const multiselectDocumentTypeRef = React.createRef();
    const multiselectEvidenceRef = React.createRef();

    const makeData = () => {

        let title = [];
        let evidence = [];
        let sides = [];
        props.data.map((item) => {
            item.children.map((subitem) => {
                if (props.selectedArg.length > 0 && props.selectedArg.filter((res) => res.id == 1).length == 1) {
                    if (subitem.argType==2) {
                        title.push({ name: subitem.title, id: subitem.title });
                    }
                }

                if (props.selectedArg.length > 0 && props.selectedArg.filter((res) => res.id == 2).length == 1) {
                    if (subitem.argType==2) {
                        title.push({ name: subitem.title, id: subitem.title });
                    }
                }

                if (props.selectedArg.length > 0 && props.selectedArg.filter((res) => res.id == 3).length == 1) {

                    subitem.children.filter(subi => subi.type == 1).map((subsubitem) => {
                        title.push({ name: subsubitem.title, id: subsubitem.title });
                    });
                }
                if (props.filter.filterEvidence == true) {
                    subitem.children.filter(subi => subi.type == 2).map((subsubitem) => {
                        evidence.push({ name: subsubitem.title, id: subsubitem.title });
                    });
                }

                if(subitem.sides.length){
                    sides.push({ name: subitem.sides[0], id: subitem.sides[0] });
                }
            });
        });
        
        const uniqueTitle = title.length && title.filter((obj, index, self) =>
            index === self.findIndex((t) => (
                t.id === obj.id && t.name === obj.name
            ))
        );

        const uniqueEvidence = evidence.length && evidence.filter((obj, index, self) =>
            index === self.findIndex((t) => (
                t.id === obj.id && t.name === obj.name
            ))
        );

        const uniqueSides = sides.length && sides.filter((obj, index, self) =>
            index === self.findIndex((t) => (
                t.id === obj.id && t.name === obj.name
            ))
        );
        
        setOptionsOrg(uniqueTitle.length ? uniqueTitle : []);
        setSidesOption(uniqueSides.length ? uniqueSides : []);
        setDocumentTypeOption([]);//setDocumentTypeOption([{ name: "cevap dklekces", id: " cevap dklekces" }]);
        setEvidenceOption(uniqueEvidence.length ? uniqueEvidence : []);

    };


    const handleOrg = (e) => {
        setSelectedOrg(e);
    };

    const handleEvidence = (e) => {
        setSelectedEvidence(e);
    };

    const handleSide = (e) => {
        setSelectedSide(e);
    };

    const handleDocumentType = (e) => {
        setSelectedDocumentType(e);
    };

    const handleCloseFilter = () => {
        props.handleCloseFilter();
    };

    const handleClearFilter = () => {

        setSelectedOrg([]);
        setSelectedEvidence([]);
        setSelectedSide([]);
        setSelectedDocumentType([]);

        multiselectEvidenceRef.current.resetSelectedValues();
        multiselectSideRef.current.resetSelectedValues();
        multiselectDocumentTypeRef.current.resetSelectedValues();
        multiselectOrgRef.current.resetSelectedValues();

        setClearFilterFlag(Math.random());


    };



    const handleApplyFilter = () => {
        let dataF = { title: selectedOrg, evidence: selectedEvidence, documentType: selectedDocumentType, side: selectedSide };
        props.handleApplyFilter(dataF);
    };

    const handelCountFilter = () => {
        let countF = 0;
        countF = selectedOrg.length + selectedSide.length + selectedDocumentType.length + selectedEvidence.length;
        setAllFilter(countF);
    };


    useEffect(() => {
        if (clearFilterFlag != 0) {
            handleApplyFilter();
        }
    }, [clearFilterFlag]);


    useEffect(() => {
        handelCountFilter();
    }, [selectedOrg, selectedSide, selectedDocumentType, selectedEvidence]);

    useEffect(() => {
        makeData();
    }, [props.refresh]);


    return (
        <div className={props.isFilter == false ? "hide filter_section" : "filter_section"} >
            <div className="card">
                <div className="card-header">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0"><img src={filter} alt="check" /> <span>Filters</span>{countAllFilter > 0 ? <span className="filternum">{countAllFilter}</span> : ""}</h5>
                        <div>
                            <a href="javascript:;" onClick={() => handleClearFilter()} ><span>Clear Filters</span> </a>
                            <img src={close} onClick={() => handleCloseFilter()} alt="check" style={{ "cursor": "pointer", marginLeft: "5px" }} />
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <form>
                        <div className="row">

                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Title</label>

                                    <Multiselect
                                        options={optionsOrg}
                                        displayValue="name"
                                        onSelect={(e) => handleOrg(e)}
                                        onRemove={(e) => handleOrg(e)}
                                        placeholder={selectedOrg.length == 0 ? "All Title" : ""}
                                        selectedValues={selectedOrg}
                                        ref={multiselectOrgRef}
                                    />

                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label className="form-label">Side</label>
                                    <Multiselect
                                        options={sidesOption}
                                        displayValue="name"
                                        onSelect={(e) => handleSide(e)}
                                        onRemove={(e) => handleSide(e)}
                                        placeholder={selectedSide.length == 0 ? "All Side" : ""}
                                        selectedValues={selectedSide}
                                        ref={multiselectSideRef}
                                    />

                                </div>
                            </div>
                            <div className="col-6">
                                <div className="mb-3">
                                    <label className="form-label">Document Type</label>
                                    <Multiselect
                                        options={documentTypeOption}
                                        displayValue="name"
                                        onSelect={(e) => handleDocumentType(e)}
                                        onRemove={(e) => handleDocumentType(e)}
                                        placeholder={selectedDocumentType.length == 0 ? "Document type" : ""}
                                        selectedValues={selectedDocumentType}
                                        ref={multiselectDocumentTypeRef}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Evidence Name</label>
                                    <Multiselect
                                        options={evidenceOption}
                                        displayValue="name"
                                        onSelect={(e) => handleEvidence(e)}
                                        onRemove={(e) => handleEvidence(e)}
                                        placeholder={selectedEvidence.length == 0 ? "All Evidence" : ""}
                                        selectedValues={selectedEvidence}
                                        ref={multiselectEvidenceRef}
                                    />
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
                <div className="card-footer">
                    {/*<div className="d-flex align-items-center justify-content-between">
                        <div>
                            <a href="#"><img src={star} alt="check" /> <span>Save Filters</span></a>
                        </div>*/}
                    <div className="text-right">
                        <div>
                            <a href="javascript:;" onClick={() => handleApplyFilter()} className="btn btn-sm">Apply</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Index;
