import React from "react";
import "./loader.css";

const Loader = ({isLoading}) => {
  return (
    <div className={isLoading ? "loader": ""}>
      <div className= {isLoading ? "spinner": ""}></div>
    </div>
  );
};

export default Loader;