import React, { useState, useEffect, useRef } from "react";
import logoColorIcon from "../../../../assets/images/logo_color.svg";
import filterIcon from "../../../../assets/images/filter.svg";
//import search 	from "../../../../assets/images/search.png";
import Loader1 from "../../../../assets/images/loader.svg";
import ArrowDown from "../../../../assets/images/arrow-down.svg";
import ArrowUp from "../../../../assets/images/arrow-up.svg";

import Dropdown from "react-bootstrap/Dropdown";
import axios from "axios";
import argumentJson from "./evidence.json";
// import apiResponse from "./apiResponse.json";
import LeftChart from "./LeftChart";
import Info from "./Info";
import Kpi from "./Kpi";
import FilterComponent from "./Filter";

import Multiselect from "multiselect-react-dropdown";

const baseURL = "http://localhost:3007/data";

function Index() {

	const [data, setData] = useState([]);
	const [orgData, setOrgData] = useState([]);

	const contentTopRef = useRef(null);
	const contentBottumRef = useRef(null);
	const [transform, setTransform] = useState([{ name: "zoom-1", percent: 50 }, { name: "zoom-2", percent: 60 }, { name: "zoom-3", percent: 70 }, { name: "zoom-4", percent: 80 }, { name: "zoom-5", percent: 90 }, { name: "zoom0", percent: 100 }, { name: "zoom1", percent: 110 }, { name: "zoom2", percent: 120 }, { name: "zoom3", percent: 130 }, { name: "zoom4", percent: 140 }, { name: "zoom5", percent: 150 }]);
	const [zoom, setZoom] = useState(5);
	const [oldZoom, setOldZoom] = useState(5);
	const [filterEvidence, setFilterEvidence] = useState(true);
	const [refresh, setRefresh] = useState(false);
	const [filterExport, setFilterExport] = useState("");
	const [isKpifeature, setIsKpifeature] = useState(false);
	const [isFilter, setIsFilter] = useState(false);
	const [selectedArg, setSelectedArg] = useState([]);
	const [disablePreSelected, setDisablePreSelected] = useState(false);
	const [filterAttribute, setFilterAttribute] = useState({ title: [], evidence: [], documentType: [], side: [] });
	const [countAllFilter, setAllFilter] = useState(0);
	const [searchFilter, setSearchFilter] = useState("");
	const [oldSearchFilter, setOldSearchFilter] = useState("");
	const [highlightCount, setHighlightCount] = useState(0);
	const [isLoader, setIsLoader] = useState(false);
	const [commentFlag, setCommentFlag] = useState(false);



	const optionsOrg = [{ name: "Reason", id: 1 }, { name: "Demand", id: 2 }, { name: "Objection", id: 3 }];
	const getArgumentList = async () => {
		const BASEURL = process.env.REACT_APP_BASEURL;
		setTransform([{ name: "zoom-1", percent: 50 }, { name: "zoom-2", percent: 60 }, { name: "zoom-3", percent: 70 }, { name: "zoom-4", percent: 80 }, { name: "zoom-5", percent: 90 }, { name: "zoom0", percent: 100 }, { name: "zoom1", percent: 110 }, { name: "zoom2", percent: 120 }, { name: "zoom3", percent: 130 }, { name: "zoom4", percent: 140 }, { name: "zoom5", percent: 150 }]);

		let projectId = localStorage.getItem("projectid");
		let caseId = localStorage.getItem("caseId");
		let token = localStorage.getItem("token");

		let header = {
			"Content-Type": "application/json",
		 	"Authorization": token,
		};
		await axios.get(BASEURL+"get?requestType=xhr&projectid=" + projectId + "&caseId=" + caseId, { headers: header })
		 	.then(response => {
				console.log(baseURL, filterAttribute, response?.data?.data,setFilterAttribute);
				// setData(response?.data?.data);
				// setOrgData(response?.data?.data);
		 	})
		 	.catch(error => console.log(error));

		setData(argumentJson.data);
		setOrgData(argumentJson.data);
		
		setRefresh(true);
		setSelectedArg(optionsOrg);
	};

	const topScroll = (direction) => {

		const { current } = contentTopRef;
		if (direction === "down") {
			contentBottumRef.current.scrollIntoView({
				behavior: "smooth",
				block: "center", inline: "nearest"
			});
		}
		else {
			current.scrollIntoView({
				behavior: "smooth",
				block: "start", inline: "nearest"
			});
		}
	};

	const scrollToNode = (topVal) => {
		const { current } = contentTopRef;
		current.scrollTo({ top: topVal, left: 100, behavior: "smooth" });
	};

	const handleZoom = (type) => {
		if (type == "up") {
			if (zoom < transform.length - 1) {
				setZoom(zoom + 1);
			}
		}
		else {
			if (zoom > 0) {
				setZoom(zoom - 1);
			}
		}
	};

	const handleFilterEvidence = (e) => {
		setFilterEvidence(e.target.checked);
		setRefresh(Math.random());
	};

	const handleFilterKpi = () => {

		setIsKpifeature(!isKpifeature);
	};

	const handleCloseFilter = () => {

		setIsFilter(!isFilter);
	};

	const handleApplyFilter = (dataF) => {
		setFilterAttribute(dataF);
		setRefresh(Math.random());
		setIsFilter(!isFilter);
		setCommentFlag(true);
	};

	const handleExport = () => {
		setIsLoader(true);
		setOldSearchFilter(searchFilter);
		setOldZoom(zoom);
		setSearchFilter("");
		setZoom(5);
		setFilterExport(Math.random());
	};

	const filterExportCallBack = () => {
		setSearchFilter(oldSearchFilter);
		setZoom(oldZoom);
		setRefresh(Math.random());
		setIsLoader(false);
	};

	const noRefCheck = (e) => {
		setSelectedArg(e);
		if (e.length == 1) {
			setDisablePreSelected(true);
		}
		else {
			setDisablePreSelected(false);
		}
		setRefresh(Math.random());
		setCommentFlag(true);
	};

	const removeRefCheck = (e) => {
		setSelectedArg(e);
		if (e.length == 1) {
			setDisablePreSelected(true);
		}
		else {
			setDisablePreSelected(false);
		}
		setRefresh(Math.random());
		setCommentFlag(true);
	};

	const handelCountFilter = () => {
		let countF = 0;
		countF = filterAttribute.title.length + filterAttribute.side.length + filterAttribute.documentType.length + filterAttribute.evidence.length;
		setAllFilter(countF);
	};

	const handleSearch = (e) => {
		setSearchFilter(e.target.value);
		setRefresh(Math.random());

	};


	useEffect(() => {
		handelCountFilter();
	}, [filterAttribute]);

	useEffect(() => {
		localStorage.setItem("projectid", 13439);
		localStorage.setItem("caseId", 1495458);
		localStorage.setItem("token", "bc3fffb5b905bcf3929c050cab43b2c3");
		localStorage.setItem("XdebugSessionStart", 19134);
		getArgumentList();
		setCommentFlag(false);
	}, []);

	useEffect(() => {
		
		//document.body.scrollLeft = (document.body.scrollWidth - document.body.clientWidth) / 2;
		//var element  =document.getElementsByTagName("body")[0];
		var scrollWidth =window.innerWidth;
		var fullWidth=document.body.scrollWidth;
		var fwidth=fullWidth-scrollWidth;
		//var fromTop=window.pageYOffset;
		console.log(document.body.scrollWidth,scrollWidth,fwidth);
		if(fwidth>0){
			fwidth=fwidth/2;
			/*setTimeout(function(){
				window.scrollTo(fwidth, fromTop,"easeInOutQuint");
			},100);*/

			window.scrollTo({ left:fwidth , behavior: "smooth" });

		}
		/*var element = document.getElementsByTagName("body")[0];
		element.scrollIntoView();
		element.scrollIntoView(false);
		element.scrollIntoView({block: "end"});
		element.scrollIntoView({behavior:"smooth", block: "end", inline:"nearest"});*/

	}, [zoom]);

	const handleComment=()=>{
        setCommentFlag(false);
    };

	return (
		<div className={`argumentmap  ${transform[zoom]["name"]} ${isLoader==true && " bgnone"} ` } ref={contentTopRef}>
			{/* Filter */}

			<div className="top_strip">
				<div className="top_strip-arrange">
					<div className="brand_logo">
						<a href="#"><img src={logoColorIcon} alt="logo" /></a>
					</div>

					{/*<h5>Argument Map</h5>*/}

					<div className="right_menu">
						<div className="top_right-action">
							<div className="search_section">

								<div className="search_field">
									<input type="text" className="form-control search form-control-sm" value={searchFilter} onChange={(e) => handleSearch(e)} placeholder="Content Search:" />
									{highlightCount > 0 && <span>{highlightCount}</span>}
								</div>
							</div>

							<div className="mx-1 d-flex">
								<label>Evidence</label>
								<label className="switch">
									<input onChange={(e) => handleFilterEvidence(e)} type="checkbox" checked={filterEvidence} />
									<span className="slider round"></span>
								</label>
							</div>
							<div>
								<div className="wrapper-dropdown lg mx-2">
									<Multiselect
										hideSelectedList
										options={optionsOrg}
										displayValue="name"
										showCheckbox
										placeholder="All Arg Type"
										selectedValues={selectedArg}
										onSelect={(e) => noRefCheck(e)}
										onRemove={(e) => removeRefCheck(e)}
										disablePreSelectedValues={disablePreSelected}
									/>
								</div>
							</div>

							<div className="divider mx-2">&nbsp;</div>
							<div>
								{ /*<div className="wrapper-dropdown open black_action sm dark mx-1">*/}
								<div className={`wrapper-dropdown black_action sm dark mx-1  ${isKpifeature == true && "open"}`}>
									<a href="javascript:;" onClick={() => handleFilterKpi()}>KPI</a>
								</div>
							</div>
							<div>
								<Dropdown className="wrapper-dropdown black_action sm dark mx-2">
									<Dropdown.Toggle variant="success" id="dropdown-basic"> Export </Dropdown.Toggle>
									<Dropdown.Menu>
										<Dropdown.Item href="javascript:;" onClick={() => handleExport()}>Pdf</Dropdown.Item>
									</Dropdown.Menu>
								</Dropdown>
							</div>

							<div>
								<div className="wrapper-dropdown black_action no-carat sm dark">
									<a href="javascript:;" onClick={() => handleCloseFilter()}><img src={filterIcon} alt="check" /> {countAllFilter > 0 && <span className="filternum">{countAllFilter}</span>}</a>
								</div>
							</div>
						</div>
					</div>
				</div>

				<FilterComponent filter={{ filterEvidence: filterEvidence }} selectedArg={selectedArg} refresh={refresh} data={data.length ? data : []} handleApplyFilter={handleApplyFilter} handleCloseFilter={handleCloseFilter} isFilter={isFilter} />
			</div>
			{/* kpi */}
			<Kpi orgData={orgData.length ? orgData : []} isKpifeature={isKpifeature} />
			{/* bilgi info */}
			<Info />
			{/* Filter end */}
			{/* Argumentmap chart */}


			<LeftChart setHighlightCount={setHighlightCount} filterExportCallBack={filterExportCallBack} searchFilter={searchFilter} filterAttribute={filterAttribute} refresh={refresh} selectedArg={selectedArg} totalHeight={contentBottumRef} filterExport={filterExport} filter={{ filterEvidence: filterEvidence }} data={data.length ? data : []} handleScrollToNode={scrollToNode} commentFlag={commentFlag} handleComment={handleComment}/>


			{/* ProgreshChart chart */}

			<div ref={contentBottumRef}> </div>

			{/* zoom option */}
			<div className="page_option">
				<div className="arrange_horrizontal">
					<div className="zoom mx-1">
						<a href="javascript:;" className={zoom == 0 ? "disabledCursor" : ""} onClick={() => handleZoom("down")}>-</a>
						<div className="mx-1">
							<a href="#">{transform[zoom]["percent"]}%</a>
						</div>
						<a href="javascript:;" className={zoom == transform.length - 1 ? "disabledCursor" : ""} onClick={() => handleZoom("up")}>+</a>
					</div>
					<div className="zoom up_down mx-1">
						<a href="javascript:;" onClick={() => topScroll("up")} ><img src={ArrowDown} /></a>
						<a href="javascript:;" onClick={() => topScroll("down")}  ><img src={ArrowUp} /></a>
					</div>
				</div>
			</div>
			<div className={`pageload  ${isLoader == true && "open"}`}><img src={Loader1} alt="logo" /></div>
		</div>
	);
}


export default Index;