import React, { useEffect, useState, useRef } from "react";
//import axios from "axios";
import closeBlack from "../../../../assets/images/close-black.svg";
import sendIcon from "../../../../assets/images/send.svg";
import profileIcon from "../../../../assets/images/profile.png";
import moreIcon from "../../../../assets/images/more.svg";
import arrowIcon from "../../../../assets/images/arrow.svg";
import edit from "../../../../assets/images/edit.svg";
import trash from "../../../../assets/images/trash.svg";
//const BASEURL = process.env.REACT_APP_BASEURL;
import Loader from "./Loader";
import getComment from "./getComment.json";


function Index(props) {
    //const [updateData, setUpdateData] = useState([]);
    //const [ setUpdateData] = useState([]);
    const [check, setCheck] = useState(0);
    const [buttonShow, setButtonShow] = useState(false);
    const [newReply, setNewReply] = useState("");
    const [modalData, setModalData] = useState([]);
    const [newComment, setNewComment] = useState("");
    const [actionId, setActionId] = useState("");
    const [isReply, setIsReply] = useState(false);
    const [replyId, setReplyId] = useState("");
    const [isScroll, setIsScroll] = useState(false);
    const [messageId, setMessageId] = useState("");
    const [showReply, setShowReply] = useState(false);
    const [fisrtScroll, setFirstScroll] = useState(0);
    const [loading, setLoading] = useState(true);

    const msgBodyRef = useRef(null);
    const inputReplyRef = useRef(null);
    const inputCommentRef = useRef(null);


    const updateCommentData = (data, type, comment) => {
        //setUpdateData(data);
        if (type === "reply") {
            setNewReply(data?.note);
            setButtonShow(false);
            setIsReply(true);
            setReplyId(comment?.id);
            setNewComment("");
            setMessageId("");
            handleReplyClick();
        } else {

            setNewComment(data.note);
            setNewReply("");
            setIsReply(false);
            handleCommentClick();
        }

        setCheck(1);
        setButtonShow(false);
        //setShowReply(false);
    };

    const submitRep = async (e) => {

        if (e.key === "Enter") {
            let reply = newReply;
            let replyValue = reply.trim();

            if (replyValue.length === 0 && replyValue === "") {
                console.log("please enter reply");
                return;
            } else {

                //let projectId = localStorage.getItem("projectid");
                //let token = localStorage.getItem("token");
                //let XdebugSessionStart = localStorage.getItem("XdebugSessionStart");

                // let header = {
                //     "Content-Type": "application/json",
                //     "Authorization": token
                // };

                if (showReply) {
                    if (newReply.length === 0) {
                        return true;
                    } else {
                        /*
                        await axios
                            .post(BASEURL+"updateComment?requestType=xhr&projectid=" + projectId + "&comment_id=" + updateData?.id, {
                                note: newReply,
                            }, { headers: header })
                            .then((response) => {
                                console.log(response);
                                setCheck(0);
                                getData();
                                setNewReply("");
                                setIsReply(false);
                                setNewComment("");
                                setMessageId("");
                                setShowReply(false);
                            });
                            */
                            getData();
                            setNewReply("");
                            setIsReply(false);
                            setNewComment("");
                            setMessageId("");
                            setShowReply(false);
                    }
                } else {
                    // let projectId = localStorage.getItem("projectid");
                    // let token = localStorage.getItem("token");
                    // let XdebugSessionStart = localStorage.getItem("XdebugSessionStart");

                    // let header = {
                    //     "Content-Type": "application/json",
                    //     "Authorization": token
                    // };
                    /*
                    await axios
                        .post(BASEURL+"addReply?requestType=xhr&projectid=" + projectId + "&XDEBUG_SESSION_START=" + XdebugSessionStart, {
                            comment_id: data?.id,
                            note: replyValue
                        }, { headers: header })
                        .then((response) => {
                            console.log(response?.data);
                            setNewComment("");
                            setNewReply("");
                            setIsReply(false);
                            getData();
                            setCheck(0);
                            setShowReply(false);
                        });
                    */
                        setNewComment("");
                        setNewReply("");
                        setIsReply(false);
                        getData();
                        setCheck(0);
                        setShowReply(false);
                }


            }
        }
    };

    const handleReply = (id) => {
        setIsReply(true);
        setReplyId(id);
        setNewReply("");
        setNewComment("");
        setMessageId("");
        setButtonShow(false);
        handleReplyClick();
        // let newData = modalData;
        // newData[index].isReply = !newData[index]?.isReply;
        // //setModalData(newData);
        // setModalData([]);
        // setTimeout(function () {
        //     setModalData(newData);
        // }, 300);
    };

    const deleteComment = async () => {

        //let projectId = localStorage.getItem("projectid");
        // let token = localStorage.getItem("token");
        // let header = {
        //     "Content-Type": "application/json",
        //     "Authorization": token
        // };

        /*
        await axios.post(BASEURL+"deleteComment?requestType=xhr&projectid=" + projectId + "&comment_id=" + data?.id, {}, { headers: header }).then((response) => {
            console.log(response);
            getData();
            setButtonShow(false);
            setNewComment("");
            setNewReply("");
            setIsReply(false);
            setCheck(0);
            setShowReply(false);
        });
        */
        getData();
        setButtonShow(false);
        setNewComment("");
        setNewReply("");
        setIsReply(false);
        setCheck(0);
        setShowReply(false);
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            msgBodyRef.current.scrollTop = msgBodyRef.current.scrollHeight;
        }, 1000);
        msgBodyRef.current.scrollBottom = msgBodyRef.current.scrollHeight;
    };

    const scrollToBottomNew = () => {
        msgBodyRef.current.scrollTop = msgBodyRef.current.scrollHeight;
    };

    const postComment = async () => {

        // let projectId = localStorage.getItem("projectid");
        // let token = localStorage.getItem("token");
        // let header = {
        //     "Content-Type": "application/json",
        //     "Authorization": token
        // };

        if (check === 0) {
            if (newComment.length === 0) {
                return;
            } else {
                /*
                await axios
                    .post(BASEURL+"addComment?requestType=xhr&projectid=" + projectId, {
                        argument_id: props.claimId,
                        note: newComment,
                        reply: [],
                        isReply: false,
                    }, { headers: header })
                    .then((response) => {
                        console.log(response);
                        setIsScroll(true);
                        getData();
                        setCheck(0);
                        setNewComment("");
                    });
                */
                    setIsScroll(true);
                    getData();
                    setCheck(0);
                    setNewComment("");
            }
        } else if (check === 1) {
            if (newComment.length === 0) {
                return;
            } else {
                /*
                await axios
                    .post(BASEURL+"updateComment?requestType=xhr&projectid=" + projectId + "&comment_id=" + updateData?.id, {
                        note: newComment,
                    }, { headers: header })
                    .then((response) => {
                        console.log(response);
                        setCheck(0);
                        getData();
                        setNewComment("");
                    });
                */
                    setCheck(0);
                    getData();
                    setNewComment("");
            }
        }
    };

    

    const getData = async () => {
        /*        
        await axios.get(BASEURL+"getComment?requestType=xhr&projectid=" + projectId + "&argument_id=" + props.claimId, { headers: header })
        .then(response => {
            response?.data?.status === "success" ? setModalData(response?.data?.data) : null;
            setNewComment("");
            setNewReply("");
            setIsReply(false);
            setIsScroll(false);
            setFirstScroll(1);
            // Simulating an asynchronous operation
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        })
        .catch(error => {
            console.log(error);
            setModalData([]);
            setLoading(false);
        });

        */

        setModalData(getComment.data);
        setNewComment("");
        setNewReply("");
        setIsReply(false);
        setIsScroll(false);
        setFirstScroll(1);
        // Simulating an asynchronous operation
        setTimeout(() => {
            setLoading(false);
        }, 1000);
        
    };

    useEffect(() => {
        
        if (fisrtScroll === 0) {
            console.log(modalData);
            ///scrollToBottom();
        }
    }, [modalData]);

    useEffect(() => {
        scrollToBottomNew();
    }, [loading]);

    useEffect(() => {
        if (isScroll) {
            scrollToBottom();
        }
    }, [isScroll]);

    useEffect(() => {
        if (props.claimId) {
            setFirstScroll(0);
            setModalData([]);
            getData();
        }
    }, [props.claimId]);
    {/*style={{ top: props.topHeight + "px",left:props.commentLeft + "%" }} */ }

    function handleReplyClick() {
        inputReplyRef?.current?.focus();
    }

    function handleCommentClick() {
        inputCommentRef?.current?.focus();
    }

    return (
        <div
            className={
                props.isFilter == false ? "hide messagebox card" : "messagebox card"
            }>
            <div className="msg_title">
                <h5>Add Comment</h5>
                <a href="javascript:;" className="close">
                    <img
                        src={closeBlack}
                        onClick={() => {
                            setButtonShow(false);
                            props.handleComment();
                        }}
                        className="icon"
                        alt="info"
                    />
                </a>
            </div>

            <div className="msg_body" ref={msgBodyRef}>
                {loading ? <Loader isLoading={loading} /> :
                    <React.Fragment>
                        {modalData.map((data, index) => {
                            return (
                                <div className="comment_content" key={data.id + index} >
                                    <div className="comment_content_header">
                                        <div>
                                            <div className="profiledtl">
                                                <div className="profileimg">
                                                    <img src={profileIcon} alt="profile" />
                                                </div>
                                                <div>
                                                    <strong className="nm">{data.user_name}</strong>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="text-muted">
                                            <span>
                                                <span>{data?.date ? data?.date : "--/--/--"}</span> <span>{data?.hour ? data?.hour : "--:--"}</span>
                                            </span>
                                            <div className="more">
                                                <a
                                                    href="javascript:;"
                                                    onClick={() => {
                                                        setButtonShow(!buttonShow);
                                                        setActionId(data?.id);
                                                        setShowReply(false);
                                                        setIsReply(false);

                                                    }}>
                                                    <img src={moreIcon} alt="more" />
                                                </a>
                                                {buttonShow && actionId === data?.id ? (
                                                    <div className="more_cnt">
                                                        <div>
                                                            <a
                                                                href="javascript:;"
                                                                onClick={() => {
                                                                    updateCommentData(data, "comment", data);
                                                                }}>
                                                                <img src={edit} alt="edit" />
                                                                <span> Edit </span>
                                                            </a>
                                                        </div>
                                                        <div>
                                                            <a
                                                                href="javascript:;"
                                                                onClick={() => {
                                                                    deleteComment(data);
                                                                }}>
                                                                <img src={trash} alt="trash" />
                                                                <span> Delete </span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <p>{data.note}</p>
                                    {data.reply.map((message, ind) => {
                                        return (
                                            <div key={ind} className="reply__msg">
                                                <ul>
                                                    <li>
                                                        <div>
                                                            <div className="profiledtl">
                                                                <div className="profileimg">
                                                                    <img src={profileIcon} alt="profile" />
                                                                </div>
                                                                <div>
                                                                    <strong className="nm">
                                                                        {message.user_name}
                                                                    </strong>
                                                                    <div>
                                                                        <span className="text-muted">
                                                                            <span>{message.date}</span>{" "}
                                                                            <span>{message.hour}</span>
                                                                        </span>
                                                                    </div>
                                                                </div>

                                                                <div className="more">
                                                                    <a href="javascript:;"
                                                                        onClick={() => {
                                                                            setShowReply(!showReply);
                                                                            setMessageId(message?.id);
                                                                            setButtonShow(false);
                                                                            setIsReply(false);
                                                                        }}
                                                                    >
                                                                        <img src={moreIcon} alt="more" />
                                                                    </a>
                                                                    {showReply && messageId === message?.id ? (
                                                                        <div className="more_cnt" >
                                                                            <div>
                                                                                <a
                                                                                    href="javascript:;"
                                                                                    onClick={() => {
                                                                                        updateCommentData(message, "reply", data);
                                                                                    }}>
                                                                                    <img src={edit} alt="edit" />
                                                                                    <span> Edit </span>
                                                                                </a>
                                                                            </div>
                                                                            <div>
                                                                                <a
                                                                                    href="javascript:;"
                                                                                    onClick={() => {
                                                                                        deleteComment(message);
                                                                                    }}>
                                                                                    <img src={trash} alt="trash" />
                                                                                    <span> Delete </span>
                                                                                </a>
                                                                            </div>
                                                                        </div>
                                                                    ) : null}
                                                                </div>

                                                            </div>
                                                            <div className="cnt">
                                                                <strong>Not:</strong>
                                                                <p>{message.note}</p>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        );
                                    })}
                                    <div className="reply hr">
                                        {isReply && replyId === data?.id ? (
                                            <div className="reply__area">
                                                <span>
                                                    <input
                                                        type="text"
                                                        placeholder="Reply"
                                                        value={newReply}
                                                        className="inputreply"
                                                        onChange={(e) => setNewReply(e.target.value)}
                                                        onKeyDown={(e) => {
                                                            submitRep(e, data);
                                                        }}
                                                        autoFocus
                                                        ref={inputReplyRef}
                                                    />
                                                    {/* <button
                                                onClick={() => {
                                                    handleReply(data, index);
                                                }}>
                                                X
                                            </button> */}
                                                </span>
                                            </div>
                                        ) : (
                                            <>
                                                <a
                                                    href="javascript:;"
                                                    className="reply__action"
                                                    onClick={() => {
                                                        handleReply(data?.id);
                                                    }}>
                                                    <img src={arrowIcon} alt="arrow" className="icon" />
                                                    Reply
                                                </a>
                                            </>

                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </React.Fragment>
                }

            </div>
            {loading ? null : 
                <div className="msg_footer">
                    <div className="form">
                        <input
                            type="text"
                            value={newComment}
                            placeholder="Comment"
                            onChange={(e) => {
                                setNewComment(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    postComment();
                                }
                            }}
                            autoFocus
                            ref={inputCommentRef}
                        />
                        <button
                            onClick={() => {
                                postComment();
                            }}>
                            <img src={sendIcon} className="" alt="send" />
                        </button>
                    </div>
                </div>
            }
        </div>
    );
}

export default Index;  
