import React, { useState, useEffect} from "react";
import addIcon 	from "../../../../assets/images/add.svg";
import questionIcon 	from "../../../../assets/images/question.svg";
import closeIcon 	from "../../../../assets/images/close.svg";
import copy from "../../../../assets/images/copy.svg";
import piechart from "../../../../assets/images/piechart.svg";
import piechart1 from "../../../../assets/images/piechart1.svg";

function Index(props) {

    const [cliamCount, setCliamCount] = useState(0);
    const [requestCount, setRequestCount] = useState(0);
    const [objectionCount, setObjectionCount] = useState(0);
    const [allDocCount, setAllDocCount] = useState(0);
    const [cliamWithEvedenceCount, setCliamWithEvedenceCount] = useState(0);
    const [cliamWithoutEvedenceCount, setCliamWithoutEvedenceCount] = useState(0);
    const [evedenceCliamCount, setEvedenceCliamCount] = useState(0);
    const [evedenceRequestCount, setEvedenceRequestCount] = useState(0);
    const [counterCliamCount, setCounterCliamCount] = useState(0);


    useEffect(() => {
        let requests=0;
        let cliams=0;
        let objections=0;
        let evedenceDoc=0;
        let objectionDoc=0;
        let allDoc=0;
        let withCliam=0;
        let withoutCliam=0;
        let evedenceCliam=0;
        let evedenceRequest=0;
        let counterCliam=0;
        props.orgData.length && props.orgData.map((item)=>{
            item.children.map((subitem)=>{
                if(subitem.argType==2)
                {
                    requests++;
                    evedenceRequest+=subitem.children.filter(subi=>subi.type==2).length;
                }
                else
                {
                    cliams++;
                    evedenceCliam+=subitem.children.filter(subi=>subi.type==2).length;
                }

                //if(subitem.title.indexOf("claim")!==-1)
                //{

                if(subitem.children.filter(subi=>subi.type==1).length)
                {
                    withCliam++;
                }
                else
                {
                    withoutCliam++;
                }
                //}
                
                objections+=subitem.children.filter(subi=>subi.type==1).length;



                evedenceDoc+=subitem.children.filter(subi=>subi.type==2 && subi.url!="").length;

                subitem.children.filter(subi=>subi.type==1).map((subsubitem)=>{
                    subsubitem.children.map((subsubsubitem)=>{
                        if(subsubsubitem.url!="")
                        {
                            objectionDoc++;
                        }
                        counterCliam++;

                    });
                });
                
            });
        });
        allDoc=evedenceDoc+objectionDoc;
        setCliamCount(cliams);  
        setRequestCount(requests);
        setObjectionCount(objections);
        setAllDocCount(allDoc);
        setCliamWithEvedenceCount(withCliam);
        setCliamWithoutEvedenceCount(withoutCliam);
        setEvedenceCliamCount(evedenceCliam);
        setEvedenceRequestCount(evedenceRequest);
        setCounterCliamCount(counterCliam);
        
        
    },[props.orgData]);

    return (
        <div className={props.isKpifeature==false ? "hide kpifeature_section":"kpifeature_section"} >
        	<ul>
        		<li className="reasons">
        			<div>
        				<a href="#"></a>
        				<div className="kpifeature_cnt">
        					<div className="kpifeature_txt">
        						<span>Number of Claims</span>
        						<div className="strong">{cliamCount}</div>
        						<span className="txt">Proof {evedenceCliamCount}</span>
        					</div>
        					<div className="kpifeature_img">
        						<div>
        							<span>
        								<img src={addIcon} alt="" className="icon" />
        							</span>
        						</div>
        					</div>
        				</div>
        			</div>
        		</li>
        		<li className="demand">
        			<div>
        				<a href="#"></a>
        				<div className="kpifeature_cnt">
        					<div className="kpifeature_txt">
							<span>Number of Requests</span>
        						<div className="strong">{requestCount}</div>
        						<span className="txt">Proof {evedenceRequestCount}</span>
        					</div>
        					<div className="kpifeature_img">
        						<div>
        							<span>
        								<img src={questionIcon} alt="" className="icon" />
        							</span>
        						</div>
        					</div>
        				</div>
        			</div>
        		</li>
        		<li className="objection">
        			<div>
        				<a href="#"></a>
        				<div className="kpifeature_cnt">
        					<div className="kpifeature_txt">
        						<span>Number of objections</span>
        						<div className="strong">{objectionCount}</div>
        						<span className="txt">Proof {counterCliamCount}</span>
        					</div>
        					<div className="kpifeature_img">
        						<div>
        							<span>
        								<img src={closeIcon} alt="" className="icon" />
        							</span>
        						</div>
        					</div>
        				</div>
        			</div>
        		</li>
        		<li className="copy">
        			<div>
        				<a href="#"></a>
        				<div className="kpifeature_cnt">
        					<div className="kpifeature_txt">
        						<span>Number of Documents</span>
        						<div className="strong">{allDocCount}</div>
        					</div>
        					<div className="kpifeature_img">
        						<div>
        							<img src={copy} alt="" className="icon" />
        						</div>
        					</div>
        				</div>
        			</div>
        		</li>
        		<li className="chart">
        			<div>
        				<a href="#"></a>
	        			<div className="both">
	        				<div className="kpifeature_cnt active">
	        					<div className="kpifeature_txt">
	        						<span className="txt">with evidence</span>
	        						<div className="strong">{cliamWithEvedenceCount}</div>
	        						{/* <span>Talep-İddia-İtiraz</span> */}
	        					</div>
	        					<div className="kpifeature_img">
	        						<div> 
	        							<span>
	        								<img src={piechart} alt="" className="icon" />
	        							</span>
	        						</div>
	        					</div>
	        				</div>
	        				<div className="kpifeature_cnt unactive">
	        					<div className="kpifeature_txt">
	        						<span className="txt">without evidence</span>
	        						<div className="strong">{cliamWithoutEvedenceCount}</div>
	        						{/* <span>Talep-İddia-İtiraz</span> */}
	        					</div>
	        					<div className="kpifeature_img">
	        						<div>
	        							<span>
	        								<img src={piechart1} alt="" className="icon" />
	        							</span>
	        						</div>
	        					</div>
	        				</div>
        				</div>
        			</div>
        		</li>
        	</ul>
        </div>
    );
}

export default Index;
